function dump(dump) {
	console.log(dump)
}

function openModal(modalId) {
	let modal = document.getElementById(modalId);
	modal.style.display = "block";
	modal.classList.add('active');
	document.documentElement.classList.add('no-scroll');
	return false;
}

function closeModal(modalId) {
	let modal = document.getElementById(modalId);
	modal.style.display = "none"
	modal.classList.remove('active');
	document.documentElement.classList.remove('no-scroll');
}

window.onclick = function (event) {
	let modal = document.getElementsByClassName('modal active');
	dump(document.getElementsByClassName('modal active'));
	for (i = 0; i < modal.length; i++) {
		if (event.target === modal[i]) {
			closeModal(modal[i].getAttribute('id'));
		}
	}
}


$(function () {
	$('a[href^="#"]').on('click', function (e) {
		slide(this.hash);
		e.preventDefault();
		return false;
	});
	if (location.hash) {
		setTimeout(function () {
			slide(location.hash);
		}, 1500);
	}

	function slide(target) {
		let elementTop = $(target).offset().top;
		$('html, body').stop().animate({
			scrollTop: elementTop
		}, 500);
	}

	$(document).on('click', '#contact-button', function () {
		slide('#contact');
		closeModal();
	});

});